import { TopAdsFiltersProps } from '../../types';
import { FlavorCategory } from 'types/components/audiences';
import { useEffect, useState } from 'react';
import { useProximaSDK } from 'hooks';
import { colors, SelectInput, SelectOption, Text } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import LoadingSpinner from 'ui/LoadingSpinner';

const IntervalSelectInputWrapper = styled.div`
  div {
    border-radius: 4px;

    > fieldset {
      border: 2px solid ${colors.neutral20} !important;
    }
  }
`;

const CapitalText = styled(Text)`
  text-transform: capitalize;
`;

const TopAdsFiltersCategories = (props: TopAdsFiltersProps) => {
  const { onFilterChange, filters } = props;
  const audiencesApi = useProximaSDK('AudiencesApi');
  const [categories, setCategories] = useState<FlavorCategory[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await audiencesApi.getFlavorCategories();

      setCategories(response.data as FlavorCategory[]);
    };

    fetchCategories();
  }, []);

  const onCategorySelect = (value: string) => {
    onFilterChange('category', value);
  };

  const options: SelectOption[] = [
    {
      label: 'All',
      value: 'all',
    },
    ...categories.map((cat) => ({
      label: cat.name,
      value: cat.id,
    })),
  ];

  if (audiencesApi.loading) return <LoadingSpinner />;

  if (filters.category === null) return <></>;

  const renderValue = () => {
    const category =
      options.find((cat) => cat.value === filters.category)?.label ?? options[0].label;
    return (
      <CapitalText variant="body2Semibold" color="neutral90">
        Category: {category}
      </CapitalText>
    );
  };

  return (
    <IntervalSelectInputWrapper>
      <SelectInput
        key={filters.category}
        onChange={onCategorySelect}
        options={options}
        value={filters.category as string}
        renderValue={renderValue}
      />
    </IntervalSelectInputWrapper>
  );
};

export default TopAdsFiltersCategories;
