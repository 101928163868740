import { CarouselIcon, colors, ImageIcon, Text, VideoIcon } from '@innovationdepartment/proxima-ui';
import {
  cellValueMultiplier,
  dollarUnitSymbol,
  percentUnitSymbol,
  roasSymbol,
} from 'components/Insights/insightsHelpers';
import { MyCreativeBreakdownRow, MyCreativeBreakdownTableCellRendererProps } from '../../types';
import styled from '@emotion/styled';
import { ReactElement } from 'react';

const CreativeTypeCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CreativeTypeText = styled(Text)`
  text-transform: capitalize;
`;

const ChartColorSquare = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  height: 24px;
  width: 24px;
`;

type CreativeTypeCellContent = {
  [key: string]: {
    icon: ReactElement;
    color: string;
  };
};

const myCreativeBreakdownRowRenderer = (
  props: MyCreativeBreakdownTableCellRendererProps<MyCreativeBreakdownRow>
) => {
  const { header, cell } = props;

  const creativeTypeCellContent: CreativeTypeCellContent = {
    image: {
      icon: <ImageIcon />,
      color: colors.purple30,
    },
    video: {
      icon: <VideoIcon />,
      color: colors.teal30,
    },
    carousel: {
      icon: <CarouselIcon />,
      color: colors.orange30,
    },
  };

  const creativeTypeLabel = cell === 'image' ? 'Static' : cell;

  if (header === 'creativeType')
    return (
      <CreativeTypeCellWrapper>
        <ChartColorSquare backgroundColor={creativeTypeCellContent[cell]?.color} />
        {creativeTypeCellContent[cell]?.icon}
        <CreativeTypeText variant="body1Semibold">{creativeTypeLabel}</CreativeTypeText>
      </CreativeTypeCellWrapper>
    );

  const correctedCellValue = cellValueMultiplier(header!, cell);

  const cellValue = Number.isNaN(Number(cell)) ? '--' : Number(correctedCellValue).toFixed(2);
  const cellPrefix = !Number.isNaN(Number(cell)) ? dollarUnitSymbol(header!) : '';
  const cellSuffix = !Number.isNaN(Number(cell))
    ? percentUnitSymbol(header!) || roasSymbol(header!)
    : '';

  return <Text variant="body2Semibold">{`${cellPrefix}${cellValue}${cellSuffix}`}</Text>;
};

export default myCreativeBreakdownRowRenderer;
