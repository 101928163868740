import * as date from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useHandleApi } from 'hooks';
import { PAGE_DEFAULT_SIZE } from 'types/components/fbTable';
import { CreativeAd, CreativeType, VideoMeta } from 'types/components/creatives';
import { useBrandStore } from 'stores';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import useCreativeAdsManager from '../TopAds/useCreativeAdsManager';
import { TopAdsViewProps } from '../TopAds/types';
import prepareQueryString from 'utils/prepareQueryString';
import { DatePickerProps } from '@innovationdepartment/proxima-ui';

type QueryStringParameters = {
  startDate?: string;
  endDate?: string;
  pageNumber?: number;
  pageSize?: string;
  includeOnlySevenDaysOfSpend?: string;
};

type DateRange = Required<DatePickerProps['dateRange']>;

const endDate = date.endOfYesterday();
const startDate = date.sub(new Date(), { days: 7 });

const useMyCreativeAds = () => {
  const tilesContainerRef = useRef<HTMLDivElement>(null);

  const { brand: currentBrand } = useBrandStore();
  const { loading: creativeAdsLoading, getVideoMetaFromAssets } = useCreativeAdsManager();
  const { loading: myCreativeAdsLoading, handleApi } = useHandleApi();

  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState<DateRange>({ endDate, startDate });
  const [ads, setAds] = useState<TopAdsViewProps['ads']>([]);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [videoMeta, setVideoMeta] = useState<Map<number, VideoMeta>>(new Map());

  const getMyCreativeAds = async (queryItems: QueryStringParameters) => {
    const queryString = prepareQueryString({
      pageSize: PAGE_DEFAULT_SIZE,
      pageNumber: 1,
      ...queryItems,
    });

    const response = await handleApi({
      endpoint: `/brand/${currentBrand.brandId}/fb/ads/my-creative?${queryString}`,
    });

    return response;
  };

  const onFetchNext = async () => {
    const resetState = () => {
      setPage(() => 1);
      setHasNextPage(true);
      setAds([]);
      setVideoMeta(new Map());
    };

    const fetchVideoMetaFromAssets = async (nextBatchAds: CreativeAd[]) => {
      const assetIds = new Set<number>();

      /* iterate through every ad */
      nextBatchAds.forEach((ad) => {
        const { creativeAssets = [] } = ad;

        if (!creativeAssets.length) return;

        /* iterate through every video asset from each ad */
        creativeAssets.forEach((next) => {
          if (!creativeAssets.length) return;
          if (next.permalinkType !== CreativeType.VIDEO) return;
          if (videoMeta.has(next.id)) return;

          assetIds.add(next.id);
        });
      });

      if ([...assetIds].length === 0) return;

      /* fetch new video meta while more ads are loaded */
      const meta = await getVideoMetaFromAssets([...assetIds]);

      /* save new meta in store */
      setVideoMeta((prevMeta) => {
        const newMeta = new Map<number, VideoMeta>(prevMeta);
        Object.entries(meta).forEach(([key, value]) => newMeta.set(parseInt(key, 10), value));
        return newMeta;
      });
    };

    setLoading(true);

    try {
      const queryItems: Record<string, string> = {
        endDate: formatDateToIsoWithoutTime(dateRange.endDate),
        startDate: formatDateToIsoWithoutTime(dateRange.startDate),
      };

      /* get ads data */
      const response = await getMyCreativeAds({ ...queryItems, pageNumber: page });
      const newAds = response.data.ads as unknown as CreativeAd[];

      /* fetch data */
      await fetchVideoMetaFromAssets(newAds);

      /* set ads *AFTER* video data was loaded */
      setAds((prevAds) => (page === 1 ? newAds : [...prevAds, ...newAds]));

      if (page === 1) tilesContainerRef.current?.scrollTo({ top: 0 });

      setPage((prevPage) => prevPage + 1);
      setHasNextPage(response.data.pageSize >= PAGE_DEFAULT_SIZE);
    } catch (e) {
      /* reset ads and page on error */
      resetState();
    } finally {
      setLoading(false);
    }
  };

  const onDateRangeChange = (dates: DateRange) => {
    setPage(() => 1);

    setDateRange(dates);
  };

  useEffect(() => {
    onFetchNext();
  }, [dateRange]);

  const formatAds = () => {
    const formatCreativeAssets = (asset: NonNullable<CreativeAd['creativeAssets']>[number]) => {
      const { assetId } = asset;
      if (!assetId) return asset;
      const meta = videoMeta.get(+assetId);
      return {
        ...asset,
        videoMeta: meta,
      };
    };

    return ads.map((ad) => ({
      ...ad,
      brand: currentBrand,
      /* map video meta to assets */
      creativeAssets: ad.creativeAssets?.map(formatCreativeAssets),
    })) as unknown as CreativeAd[];
  };

  const formattedAds = formatAds();

  const isLoading = loading || creativeAdsLoading || myCreativeAdsLoading;

  return {
    ads: formattedAds,
    loading: isLoading,
    dateRange,
    tilesContainerRef,
    onFetchNext: hasNextPage ? onFetchNext : undefined,
    onDateRangeChange,
  };
};

export default useMyCreativeAds;
