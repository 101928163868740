import { InsightsHeader } from '../SharedLayout';
import { /* SavedAdSortOptions, */ SavedAdsViewProps } from './types';
import { Text } from '@innovationdepartment/proxima-ui';
import {
  AdsContainer,
  AdsContainerTopContainer,
  AdsStickyTop,
  SelectedTopPerformerContainer,
  SelectedTopPerformers,
  StyledMetaIcon,
  // StyledSelectInput,
} from './Layout';
import AdsTiles from '../SharedLayout/AdCreativesTiles';

// const SORT_OPTIONS = [
//   {
//     label: 'Recently Saved',
//     value: SavedAdSortOptions.RecentlySaved,
//   },
// ];

const SavedAdsView = (props: SavedAdsViewProps) => {
  const {
    ads,
    brand,
    // sortBy,
    saving,
    loading,
    trialEndDate,
    tilesContainerRef,
    onFetchNext,
    onTileClick,
    // onSortByChange,
    onToggleSavedAd,
    onOpenManageSubscriptionModal,
  } = props;

  // const label = SORT_OPTIONS.find((option) => option.value === sortBy)?.label;

  return (
    <>
      <AdsStickyTop>
        <InsightsHeader
          title="Saved Ads"
          brandName={brand.name}
          helpLink=""
          trialEndDate={trialEndDate}
          onOpenManageSubscriptionModal={onOpenManageSubscriptionModal}
        />
      </AdsStickyTop>
      <SelectedTopPerformers>
        <SelectedTopPerformerContainer>
          <StyledMetaIcon />
          <Text variant="body1">Meta Top Performers</Text>
        </SelectedTopPerformerContainer>
      </SelectedTopPerformers>
      <AdsContainer>
        <AdsContainerTopContainer>
          <Text color="neutral90" variant="h6" isTermina={false}>
            All Saved Ads ({ads.length})
          </Text>
          {/* TODO(Jenky): Disabled until I figure out how to sort these in the backend. We have 2 separate projects and the one that's supposed to do the sorting does not handle it well at the moment */}
          {/* <StyledSelectInput
            showExpandIcon={false}
            options={SORT_OPTIONS}
            value={sortBy}
            onChange={(value) => onSortByChange(value as SavedAdSortOptions)}
            renderValue={() => (
              <Text color="neutral90" variant="body2Semibold">
                Sort by: {label}
              </Text>
            )}
          /> */}
        </AdsContainerTopContainer>
        <AdsTiles
          ads={ads}
          brand={brand}
          loading={loading}
          onTileClick={onTileClick}
          onToggleSaveAd={onToggleSavedAd}
          saving={saving}
          tilesContainerRef={tilesContainerRef}
          onFetchNext={onFetchNext}
        />
      </AdsContainer>
    </>
  );
};

export default SavedAdsView;
