import { CreativeAd } from 'types/components/creatives';
import { Brand } from 'types/stores/brand-store';

export type SavedAdsViewProps = {
  ads: CreativeAd[];
  trialEndDate: string;
  onOpenManageSubscriptionModal: () => void;
  brand: Brand;
  sortBy: SavedAdSortOptions;
  onSortByChange: (sortBy: SavedAdSortOptions) => void;
  loading: boolean;
  saving: boolean;
  tilesContainerRef: React.RefObject<HTMLDivElement>;
  onTileClick: (creativeAd: CreativeAd) => void;
  onToggleSavedAd: (adId: string) => void;
  onFetchNext?: () => Promise<void>;
};

export enum SavedAdSortOptions {
  RecentlySaved = 'recently-saved',
}
