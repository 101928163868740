import styled from '@emotion/styled';
import { Button, colors, Text, Bookmark, BookmarkFilled } from '@innovationdepartment/proxima-ui';
import { useFeatureFlag } from 'hooks';

type AdCreativeTileSaveButtonProps = {
  onClick: () => void;
  saved: boolean;
};

const StyledButton = styled(Button)<{ saved: boolean }>`
  padding: 4px 12px;
  min-width: unset;
  max-width: 360px;
  max-height: 28px;
  border-color: ${colors.neutral10};
  margin-top: 12px;
  ${({ saved }) => {
    if (!saved) return '';
    return `background-color: ${colors.neutral10};`;
  }}
`;

const AdCreativeTileSaveButton = (props: AdCreativeTileSaveButtonProps) => {
  const { onClick, saved } = props;
  const { savedAds } = useFeatureFlag();

  if (!savedAds) return null;

  const onButtonClick = (e: React.MouseEvent<HTMLElement> | undefined) => {
    e?.preventDefault();
    e?.stopPropagation();

    onClick();
  };

  return (
    <StyledButton
      fullWidth
      label={undefined}
      onClick={onButtonClick}
      color="secondary"
      variant="outlined"
      saved={saved}
      startIcon={saved ? <BookmarkFilled /> : <Bookmark />}
    >
      <Text color="neutral90" variant="body2Semibold">
        {saved ? 'Saved' : 'Save'}
      </Text>
    </StyledButton>
  );
};

export default AdCreativeTileSaveButton;
