import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { TopAdsFiltersProps } from '../../types';
import TopAdsFiltersDatePicker from './TopAdsFilters.DatePicker';
import TopAdsFiltersStaticCopy from './TopAdsFilters.StaticCopy';
import TopAdsFiltersCategories from './TopAdsFilters.Categories';
//* TODO (Jenky): Disabled as data is still facing challenges on consistency. Link to the conversation: https://proxima-dojomojo.slack.com/archives/C08C79Y1Y0Y/p1738790898234989 *//
// import TopAdsFiltersCreativeType from './TopAdsFilters.CreativeType';

const FiltersContainer = styled.div`
  padding: 12px 32px;
  background-color: ${colors.neutral0};
  width: calc(100% + 64px);
  margin: -32px 0 0 -32px;
  display: flex;
  border-bottom: 1px solid ${colors.neutral20};

  & > div {
    flex: 1;
    gap: 12px;
    display: flex;

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;
    }
  }
`;

const FiltersRight = styled.div``;

const FiltersLeft = styled.div``;

const TopAdsFiltersView = (props: TopAdsFiltersProps) => (
  <FiltersContainer>
    <FiltersRight>
      <TopAdsFiltersStaticCopy />
    </FiltersRight>
    <FiltersLeft>
      {/* TODO (Jenky): Disabled as data is still facing challenges on consistency. Link to the conversation: https://proxima-dojomojo.slack.com/archives/C08C79Y1Y0Y/p1738790898234989 */}
      {/* <TopAdsFiltersCreativeType {...props} /> */}
      <TopAdsFiltersCategories {...props} />
      <TopAdsFiltersDatePicker {...props} />
    </FiltersLeft>
  </FiltersContainer>
);

export default TopAdsFiltersView;
