export default function prepareQueryString(
  props: { [key: string]: any },
  { allowEmptyValuesOnQuery = false } = {}
) {
  const queryString = new URLSearchParams();

  Object.entries(props).forEach(([key, value]) => {
    if (allowEmptyValuesOnQuery) queryString.append(key, value);
    else if (value) queryString.append(key, value);
  });

  return queryString.toString();
}
