import { useHandleApi } from 'hooks';
import { useParams } from 'react-router-dom';
import * as date from 'date-fns';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import prepareQueryString from 'utils/prepareQueryString';
import { MyCreativeBreakdownContainerProps } from './types';
import { useEffect, useState } from 'react';
import { DatePickerProps } from '@innovationdepartment/proxima-ui';

type DateRange = Required<DatePickerProps['dateRange']>;

const endDate = date.endOfYesterday();
const startDate = date.sub(new Date(), { days: 7 });

const defaultCreativeBreakdown = {
  chartData: { carousel: 0, static: 0, totalActiveAds: 0, video: 0 },
  tableData: [],
};

const useCreativeBreakdown = () => {
  const { brandId } = useParams();
  const { handleApi, loading } = useHandleApi();
  const [dateRange, setDateRange] = useState<DateRange>({ endDate, startDate });
  const [breakdown, setBreakdown] =
    useState<MyCreativeBreakdownContainerProps>(defaultCreativeBreakdown);

  // TODO (MM): remove when SDK is updated
  const getCreativeBreakdown = async () => {
    const queryItems: Record<string, string> = {
      endDate: formatDateToIsoWithoutTime(dateRange.endDate),
      startDate: formatDateToIsoWithoutTime(dateRange.startDate),
    };
    const queryString = prepareQueryString({
      ...queryItems,
    });

    const endpoint = `/brand/${brandId}/fb/ads/creatives/breakdown?${queryString}`;

    const response = await handleApi({ endpoint });

    setBreakdown(response.data);
  };

  const onDateRangeChange = (dates: DateRange) => {
    setDateRange(dates);
  };

  useEffect(() => {
    getCreativeBreakdown();
  }, [dateRange]);

  return {
    breakdown,
    loading,
    onDateRangeChange,
    getCreativeBreakdown,
  };
};

export default useCreativeBreakdown;
