import styled from '@emotion/styled';
import { colors, MetaNoBorder, SelectInput } from '@innovationdepartment/proxima-ui';

export const AdsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background-color: white;
  height: calc(100% - 118px);
`;

export const AdsContainerTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AdsContainerTilesContainer = styled.div``;

export const SelectedTopPerformers = styled.div`
  padding: 6px 32px;
  display: flex;
  border-bottom: 1px solid ${colors.neutral20};
`;

export const SelectedTopPerformerContainer = styled.div`
  display: flex;
  gap: 0px;
  align-items: center;
  flex: 1;
`;

export const StyledMetaIcon = styled(MetaNoBorder)`
  margin-left: -4px;
  transform: scale(0.6);
`;

export const AdsStickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

export const StyledSelectInput = styled(SelectInput)`
  & fieldset {
    border: none;
  }
`;
