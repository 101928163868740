import { useBrandStore } from 'stores';
import { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { updateQueryStringParams } from '../insightsHelpers';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import MyCreativeView from './MyCreative.View';
import { MyCreativeSortBy, sortByOptions } from './types';
import { useFacebook, useShowSpinner } from 'hooks';
import { CreativeAd } from 'types/components/creatives';
import MyCreativeQuickviewModal from './Layout/MyCreativeQuickviewModal';
import { sortAdsBySpend } from './Layout/helpers';
import { colors } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import { BrandIntegrationStatus } from 'constants/integrations';
import FacebookIntegrationModal from 'ui/Modals/FacebookIntegrationModal';
import useMyCreativeAds from './useMyCreativeAds';
import useCreativeBreakdown from './useCreativeBreakdown';

const Container = styled.div`
  background-color: ${colors.white};
`;

const MyCreativeContainer = () => {
  const { brand } = useBrandStore();
  const [searchParams] = useSearchParams();
  const { getMetaStatus } = useFacebook();
  const {
    ads,
    loading: adsLoading,
    tilesContainerRef,
    onDateRangeChange,
    onFetchNext,
  } = useMyCreativeAds();
  const {
    breakdown,
    loading: breakdownLoading,
    onDateRangeChange: onBreakdownDateRangeChange,
  } = useCreativeBreakdown();

  const today = dateFns.startOfToday();
  const sevenDaysAgo = dateFns.sub(today, { days: 7 }).toISOString();
  const endOfYesterday = dateFns.startOfDay(dateFns.endOfYesterday()).toISOString();

  const startDateFromSearchParams = dateFns.parseISO(
    searchParams?.get('startDate') || sevenDaysAgo
  );
  const endDateFromSearchParams = dateFns.parseISO(searchParams?.get('endDate') || endOfYesterday);

  const [sortedAds, setSortedAds] = useState<typeof ads>([]);
  const [sortBy, setSortBy] = useState<MyCreativeSortBy>(MyCreativeSortBy.HIGHESTSPEND);
  const [selectedAd, setSelectedAd] = useState<CreativeAd | null>(null);
  const [showQuickview, setShowQuickview] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: startDateFromSearchParams,
    endDate: endDateFromSearchParams,
  });
  const [metaStatus, setMetaStatus] = useState<BrandIntegrationStatus>();
  const [showMetaIntegrationModal, setShowMetaIntegrationModal] = useState<boolean>(false);

  const onDateRangeSelect = (newDateRange: any) => {
    setDateRange(newDateRange);
    onDateRangeChange(newDateRange);
    onBreakdownDateRangeChange(newDateRange);

    updateQueryStringParams({
      key: 'startDate',
      value: formatDateToIsoWithoutTime(newDateRange.startDate),
    });
    updateQueryStringParams({
      key: 'endDate',
      value: formatDateToIsoWithoutTime(newDateRange.endDate),
    });
  };

  const onSortByChanged = (newSortBy: MyCreativeSortBy) => {
    setSortBy(newSortBy);

    const newSortedAds = sortAdsBySpend(ads, newSortBy);

    setSortedAds(newSortedAds);
  };

  const onTileClick = (creativeAd: CreativeAd) => {
    setSelectedAd(creativeAd);

    setShowQuickview(true);
  };

  const onCloseModal = () => {
    setShowQuickview(false);

    setTimeout(() => {
      setSelectedAd(null);
    }, 250);
  };

  useEffect(() => {
    const initializeAds = () => {
      const newSortedAds = sortAdsBySpend(ads, sortBy);

      setSortedAds(newSortedAds);
    };

    initializeAds();
  }, [adsLoading]);

  useEffect(() => {
    const initialMetaStatus = getMetaStatus();

    setMetaStatus(initialMetaStatus);
  }, []);

  const loading = adsLoading || breakdownLoading;

  useShowSpinner({ show: loading });

  return (
    <Container>
      <MyCreativeView
        ads={sortedAds}
        brand={brand}
        creativeBreakdown={breakdown}
        onDateRangeSelect={onDateRangeSelect}
        metaStatus={metaStatus}
        dateRange={dateRange}
        loading={adsLoading}
        tilesContainerRef={tilesContainerRef}
        count={ads.length}
        sortByOptions={sortByOptions}
        sortBy={sortBy}
        onConnectMetaAccountClicked={() => setShowMetaIntegrationModal(true)}
        onFetchNext={onFetchNext}
        onTileClick={onTileClick}
        onSortByChanged={onSortByChanged}
      />
      <FacebookIntegrationModal
        onClose={() => setShowMetaIntegrationModal(false)}
        open={showMetaIntegrationModal}
      />
      <MyCreativeQuickviewModal ad={selectedAd} onClose={onCloseModal} open={showQuickview} />
    </Container>
  );
};

export default MyCreativeContainer;
