import styled from '@emotion/styled';
import {
  colors,
  InfoDarkSmall,
  SelectInput,
  Text,
  Tooltip,
} from '@innovationdepartment/proxima-ui';
import { MyCreativeSortBy, MyCreativeViewProps } from './types';
import { MyCreativeTiles } from './Layout/Tiles';
import {
  MyCreativeHeader,
  MyCreativeSubheader,
  MyCreativeNoMetaIntegration,
  MyCreativeNoData,
} from './Layout';
import { MyCreativeBreakdown } from './Layout/Breakdown';
import { BrandIntegrationStatus } from 'constants/integrations';

const ViewContainer = styled.div`
  background-color: ${colors.white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 32px;
`;

const TableHeaderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  padding-top: 28px;
`;

const TooltipWrapper = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap = 8 }) => gap}px;
  padding-top: 2px;

  & :is(svg, path) {
    fill: ${colors.neutral60};
    height: 20px;
    width: 20px;
  }
`;

const SortBySelectWrapper = styled.div`
  line-height: 0;
  div {
    div[role='combobox'] {
      padding: 0;
    }

    > fieldset {
      border: none !important;
    }
  }
`;

const MY_CREATIVE_TOOLTIP_CONTENT = (
  <div style={{ maxWidth: 260, padding: '8px 12px' }}>
    <Text variant="body2Semibold" color="white">
      All creative that saw active spend during the selected date range.
    </Text>
  </div>
);

const MyCreativeView = ({
  ads,
  brand,
  creativeBreakdown,
  count,
  dateRange,
  loading,
  metaStatus,
  sortByOptions,
  sortBy,
  tilesContainerRef,
  onSortByChanged,
  onTileClick,
  onDateRangeSelect,
  onConnectMetaAccountClicked,
}: MyCreativeViewProps) => {
  const showIntegrationScreen = metaStatus !== BrandIntegrationStatus.Connected;
  const showNoAdsScreen = ads.length === 0 && metaStatus === BrandIntegrationStatus.Connected;

  return (
    <>
      <MyCreativeHeader brandName={brand.name} title="My Creative" />
      {showNoAdsScreen && <MyCreativeNoData />}
      {showIntegrationScreen && (
        <MyCreativeNoMetaIntegration onConnectMetaAccountClicked={onConnectMetaAccountClicked} />
      )}
      <MyCreativeSubheader dateRange={dateRange} onDateRangeSelect={onDateRangeSelect} />
      <MyCreativeBreakdown
        chartData={creativeBreakdown.chartData}
        tableData={creativeBreakdown.tableData}
      />
      <ViewContainer>
        <TableHeader>
          <TableHeaderItem>
            <Text variant="h6" isTermina={false}>
              All Creative ({count})
            </Text>
            <Tooltip arrow size="small" title={MY_CREATIVE_TOOLTIP_CONTENT}>
              <TooltipWrapper gap={4}>
                <InfoDarkSmall />
              </TooltipWrapper>
            </Tooltip>
          </TableHeaderItem>
          <TableHeaderItem>
            <Text variant="body2Semibold">Sort by: </Text>
            <SortBySelectWrapper>
              <SelectInput
                onChange={(e) => onSortByChanged(e as MyCreativeSortBy)}
                options={sortByOptions}
                value={sortBy}
              />
            </SortBySelectWrapper>
          </TableHeaderItem>
        </TableHeader>
        <MyCreativeTiles
          ads={ads}
          loading={loading}
          onTileClick={onTileClick}
          tilesContainerRef={tilesContainerRef}
        />
      </ViewContainer>
    </>
  );
};

export default MyCreativeView;
