import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';
import ModalBase from 'ui/ModalBase';
import { AdCreativePreviewModalProps } from './types';
import AdCreativePreviewModalPreview from './AdCreative.PreviewModalPreview.Container';
import AdCreativePreviewModalAdDetails from './AdCreative.PreviewModalAdDetails';

const MainContentWrapper = styled.div`
  border-top: 1px solid ${colors.neutral10};
  max-width: 932px;
  display: flex;
`;

const AdCreativePreviewModal = (props: AdCreativePreviewModalProps) => {
  const { open, onClose } = props;

  return (
    <ModalBase
      allowCloseOnClickOutside
      variant="default"
      title={
        <Text variant="h4" isTermina={false}>
          Ad Quickview
        </Text>
      }
      open={open}
      onClose={onClose}
    >
      <MainContentWrapper>
        <AdCreativePreviewModalPreview {...props} />
        <AdCreativePreviewModalAdDetails {...props} />
      </MainContentWrapper>
    </ModalBase>
  );
};

export default AdCreativePreviewModal;
