import { Divider } from '@innovationdepartment/proxima-ui';
import { MyCreativeTilesTileProps } from '../../types';
import MyCreativeTilesTileMetrics from './MyCreative.Tiles.Tile.Metrics';
import { truncateText } from '../helpers';
import MyCreativeTilesTileFooter from './MyCreative.Tiles.Tile.Footer';
import AdTile from 'components/Insights/SharedLayout/AdCreativeTile';

const MyCreativeTilesTile = (props: MyCreativeTilesTileProps) => {
  const { ad, thumbnail, shouldShowInfiniteScroll, onTileClick, onFetchNext, tag } = props;

  return (
    <AdTile
      ad={ad}
      thumbnail={thumbnail}
      shouldShowInfiniteScroll={shouldShowInfiniteScroll}
      onTileClick={onTileClick}
      onFetchNext={onFetchNext}
      tag={tag}
      cardTitle={truncateText(ad?.name || '')}
      bodyElements={
        <>
          <MyCreativeTilesTileMetrics ad={ad} />
          <Divider margin={0} />
          <MyCreativeTilesTileFooter adStatus={ad.status} />
        </>
      }
    />
  );
};

export default MyCreativeTilesTile;
