import { useState } from 'react';
import { useBrandStore } from 'stores';
import { CreativeAd } from 'types/components/creatives';
import usePerformanceAdsManager from './usePerformanceAdsManager';
import TopAdsView from './TopAds.View';
import { FilterValues, TopAdsFilterType } from './types';
import { useShowSpinner } from 'hooks';
import AdCreativePreviewModal from '../SharedLayout/AdCreativePreviewModal';

const TopAds = () => {
  const { brand } = useBrandStore();
  const [showPreview, setShowPreview] = useState(false);
  const [adToPreview, setAdToPreview] = useState<CreativeAd | null>(null);

  const {
    ads,
    tab,
    loading,
    filters,
    saving,
    tilesContainerRef,
    onDateRangeChange,
    onBrandCategoryChange,
    onTabChange,
    onFetchNext,
    onSaveAdButtonClick,
    onCreativeTypeChange,
  } = usePerformanceAdsManager();

  const onTileClick = (creativeAd: CreativeAd) => {
    setAdToPreview(creativeAd);
    setShowPreview(true);
  };

  const onCloseModal = () => {
    setShowPreview(false);
    setTimeout(() => {
      setAdToPreview(null);
    }, 250);
  };

  const onFilterChange = (type: TopAdsFilterType, value: any) => {
    switch (type) {
      case 'category':
        onBrandCategoryChange(value);
        break;
      case 'dateRange':
        onDateRangeChange(value);
        break;
      case 'tab':
        onTabChange(value);
        break;
      case 'type':
        onCreativeTypeChange(value);
        break;
      default:
        break;
    }
  };

  useShowSpinner({ show: saving });

  const adToPreviewInModal = ads.find((ad) => ad.adId === adToPreview?.adId) ?? null;

  return (
    <>
      <TopAdsView
        onToggleSaveAd={onSaveAdButtonClick}
        tilesContainerRef={tilesContainerRef}
        filters={filters as FilterValues}
        tab={tab}
        ads={ads}
        brand={brand}
        loading={loading}
        saving={saving}
        onFetchNext={onFetchNext}
        onTileClick={onTileClick}
        onFilterChange={onFilterChange}
      />
      <AdCreativePreviewModal
        open={showPreview}
        ad={adToPreviewInModal}
        onClose={onCloseModal}
        onToggleSaveAd={onSaveAdButtonClick}
      />
    </>
  );
};

export default TopAds;
