import * as date from 'date-fns';
import { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { FilterValues, TopAdsViewProps } from './types';
import { useAdManager, useProximaSDK } from 'hooks';
import { BrandItem } from '@innovationdepartment/proxima-sdk-axios';
import { PAGE_DEFAULT_SIZE } from 'types/components/fbTable';
import { CreativeAd, CreativeType, VideoMeta } from 'types/components/creatives';
import { useBrandStore } from 'stores';
import useCreativeAdsManager from './useCreativeAdsManager';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import useCreativesBoard from 'hooks/useCreativesBoard';

const endDate = date.endOfYesterday();
const startDate = date.sub(new Date(), { days: 7 });

type Tab = TopAdsViewProps['tab'];

type FilterState = {
  dateRange: FilterValues['dateRange'];
  tab: Tab;
  category: string | null;
  type: CreativeType | 'all';
};

type FilterAction = {
  type: keyof FilterState;
  value: FilterState[keyof FilterState];
};

type FilterReducer = Reducer<FilterState, FilterAction>;

/* TODO(Jenky): unit tests! */
/* TODO(Jenky): might split the logic for this hook, its doing too many things! */
const usePerformanceAdsManager = ({ onlySavedAds = false, skipCategory = false } = {}) => {
  const tilesContainerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const { brand: currentBrand } = useBrandStore();
  const { loading: creativeAdsLoading, getVideoMetaFromAssets } = useCreativeAdsManager();
  const {
    loading: creativeBoardLoading,
    saveAdToBoard,
    deleteAdFromBoard,
    savedAds,
    saving,
    loaded: creativeBoardsLoaded,
  } = useCreativesBoard();
  const { getPeformanceAds } = useAdManager();
  const brandsApi = useProximaSDK('BrandsApi');
  const audiencesApi = useProximaSDK('AudiencesApi');
  const [ads, setAds] = useState<TopAdsViewProps['ads']>([]);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState<Map<string, BrandItem>>(new Map());
  const [hasNextPage, setHasNextPage] = useState(true);
  const [videoMeta, setVideoMeta] = useState<Map<number, VideoMeta>>(new Map());

  // TODO(Jenky): Replace with filters once this type of filter is implemented
  const [tab, setTab] = useState<TopAdsViewProps['tab']>('all');

  const initialState: FilterState = {
    tab: 'all',
    type: 'all',
    category: null,
    dateRange: { endDate, startDate },
  };

  const [filters, dispatch] = useReducer<FilterReducer>(
    (state, action) => ({
      ...state,
      [action.type]: action.value,
    }),
    initialState
  );

  const onFetchNext = async () => {
    const resetState = () => {
      setPage(() => 1);
      setHasNextPage(true);
      setAds([]);
      setVideoMeta(new Map());
    };

    const fetchBrandsFromAds = async (nextBatchIds: string[]) => {
      const brandIds = new Set<string>(nextBatchIds);
      /* filter brands that we already have from other batch calls */
      nextBatchIds.forEach((brandId) => {
        if (brands?.has(brandId)) brandIds.delete(brandId);
      });

      if ([...nextBatchIds].length === 0) return;

      /* fetch new brands while more ads are loaded */
      const getBrandsFromApi = async () => {
        const response = await brandsApi.getBrandBatch({ brandId: [...brandIds].join(',') });

        if (response.status === 200) {
          setBrands((prevBrands) => {
            const newBrands = new Map<string, BrandItem>(prevBrands);

            response.data.forEach((brand) => newBrands.set(brand.brandId as string, brand));

            return newBrands;
          });
        }
      };

      getBrandsFromApi();
    };

    const fetchVideoMetaFromAssets = async (nextBatchAds: CreativeAd[]) => {
      const assetIds = new Set<number>();
      /* iterate through every ad */
      nextBatchAds.forEach((ad) => {
        const { creativeAssets = [] } = ad;
        if (!creativeAssets.length) return;

        /* iterate through every video asset from each ad */
        creativeAssets.forEach((next) => {
          if (!creativeAssets.length) return;
          if (next.permalinkType !== CreativeType.VIDEO) return;
          if (videoMeta.has(next.id)) return;
          assetIds.add(next.id);
        });
      });

      if ([...assetIds].length === 0) return;

      /* fetch new video meta while more ads are loaded */
      const meta = await getVideoMetaFromAssets([...assetIds]);

      /* save new meta in store */
      setVideoMeta((prevMeta) => {
        const newMeta = new Map<number, VideoMeta>(prevMeta);
        Object.entries(meta).forEach(([key, value]) => newMeta.set(parseInt(key, 10), value));
        return newMeta;
      });
    };

    setLoading(true);
    try {
      const queryItems: Record<string, string> = {
        endDate: formatDateToIsoWithoutTime(filters.dateRange.endDate),
        startDate: formatDateToIsoWithoutTime(filters.dateRange.startDate),
      };

      /* add category filter when different from all */
      if (filters.category !== 'all' && !skipCategory) {
        queryItems.category = filters.category as string;
      }

      if (filters.type !== 'all') {
        queryItems.type = filters.type;
      }

      if (tab !== 'all') {
        queryItems.filterBy = tab;
      }

      if (onlySavedAds) {
        queryItems.adId = savedAds.join(',');
      }

      /* get ads data */
      const response = await getPeformanceAds({ pageNumber: page, queryItems });
      const newAds = response.data.ads as unknown as CreativeAd[];

      /* retrieve (new) brands info */
      const brandIds = [...new Set(newAds.map((ad) => ad.brandId))];

      /* fetch data */
      await Promise.all([fetchBrandsFromAds(brandIds), fetchVideoMetaFromAssets(newAds)]);
      /* set ads *AFTER* video data was loaded */
      setAds((prevAds) => (page === 1 ? newAds : [...prevAds, ...newAds]));

      if (page === 1) tilesContainerRef.current?.scrollTo({ top: 0 });

      setPage((prevPage) => prevPage + 1);
      setHasNextPage(response.data.pageSize >= PAGE_DEFAULT_SIZE);
    } catch (e) {
      /* reset ads and page on error */
      resetState();
    } finally {
      setLoading(false);
    }
  };

  const onTabChange = (nextTab: Tab) => {
    setTab(nextTab);
    setPage(() => 1);
    dispatch({ type: 'tab', value: nextTab });
  };

  const onDateRangeChange = (dates: FilterValues['dateRange']) => {
    setPage(() => 1);
    dispatch({ type: 'dateRange', value: dates });
  };

  const onBrandCategoryChange = (category: string) => {
    setPage(() => 1);
    dispatch({ type: 'category', value: category });
  };

  const onCreativeTypeChange = (type: CreativeType) => {
    setPage(() => 1);
    dispatch({ type: 'type', value: type });
  };

  /* I feel this should be loaded when the brand is loaded and saved in the brand store, more convos to have */
  const getBrandCategory = async () => {
    const response = await audiencesApi.getBrandSettings({ brandId: currentBrand.brandId });
    dispatch({ type: 'category', value: response.data.flavorCategoryId ?? 'all' });
  };

  const onSaveAdButtonClick = async (adId: string) => {
    const isSaved = savedAds.includes(adId);

    if (isSaved) {
      await deleteAdFromBoard(adId);
      setAds(ads.filter((ad) => ad.adId !== adId));
    } else {
      await saveAdToBoard(adId);
    }
  };

  useEffect(() => {
    const isInitialFetch = filters.category === null && !skipCategory;
    /* allow brand category to be fetched before filter by category */
    if (isInitialFetch) {
      getBrandCategory();
      return;
    }

    if (!onlySavedAds || creativeBoardsLoaded) {
      onFetchNext();
    }
  }, [filters, creativeBoardsLoaded]);

  const formatAds = () => {
    const formatCreativeAssets = (asset: NonNullable<CreativeAd['creativeAssets']>[number]) => {
      const { assetId } = asset;
      if (!assetId) return asset;
      const meta = videoMeta.get(+assetId);
      return {
        ...asset,
        videoMeta: meta,
      };
    };

    return ads.map((ad) => ({
      ...ad,
      brand: brands.get(ad.brandId as string),
      /* this should be handled in the backend */
      creativeType: ad.creativeAssets?.some((asset) => asset.permalinkType === CreativeType.VIDEO)
        ? 'VIDEO'
        : 'STATIC',
      /* map video meta to assets */
      creativeAssets: ad.creativeAssets?.map(formatCreativeAssets),
      isSaved: savedAds.includes(ad.adId),
    })) as unknown as CreativeAd[];
  };

  const formattedAds = formatAds();

  const isLoading =
    loading || creativeAdsLoading || brandsApi.loading || (!saving && creativeBoardLoading);

  return {
    ads: formattedAds,
    loading: isLoading,
    saving,
    filters,
    tab, // TODO(Jenky): Replace with filters
    tilesContainerRef,
    onFetchNext: hasNextPage ? onFetchNext : undefined,
    onTabChange,
    onDateRangeChange,
    onBrandCategoryChange,
    onCreativeTypeChange,
    onSaveAdButtonClick,
  };
};

export default usePerformanceAdsManager;
