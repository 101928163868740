import { useEffect, useState } from 'react';
import { useBrandStore } from 'stores';
import SavedAdsView from './SavedAds.View';
import { useBillingApi } from 'api';
import { SavedAdSortOptions } from './types';
import BuildPlanModal from 'components/Billing/Stripe/Subscriptions/BuildPlanModal/BuildPlanModal.Container';
import usePerformanceAdsManager from '../TopAds/usePerformanceAdsManager';
import { CreativeAd } from 'types/components/creatives';
import AdCreativePreviewModal from '../SharedLayout/AdCreativePreviewModal';
import { useShowSpinner } from 'hooks';

const SavedAds = () => {
  const { brand } = useBrandStore();
  const { getBrandSubscription } = useBillingApi();
  const [trialEndDate, setTrialEndDate] = useState('');
  const [sortBy, setSortBy] = useState(SavedAdSortOptions.RecentlySaved);
  const [openManageSubscriptionModal, setOpenManageSubscriptionModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [adToPreview, setAdToPreview] = useState<CreativeAd | null>(null);

  const { ads, loading, saving, tilesContainerRef, onSaveAdButtonClick, onFetchNext } =
    usePerformanceAdsManager({ onlySavedAds: true, skipCategory: true });

  const onOpenManageSubscriptionModal = () => {
    setOpenManageSubscriptionModal(true);
  };

  const onCloseManageSubscriptionModal = () => {
    setOpenManageSubscriptionModal(false);
  };

  /* get subscription for trial banner */
  useEffect(() => {
    const getSubscription = async () => {
      const response = await getBrandSubscription(brand.brandId);
      if (response.error) return;
      const { trialEndDate: endDate } = response.data;
      setTrialEndDate(endDate);
    };

    getSubscription();
  }, []);

  const onTileClick = (creativeAd: CreativeAd) => {
    setAdToPreview(creativeAd);
    setShowPreview(true);
  };

  const onCloseModal = () => {
    setShowPreview(false);
    setTimeout(() => {
      setAdToPreview(null);
    }, 250);
  };

  useShowSpinner({ show: saving });

  const wasRemovedOnPreview =
    ads.find((ad) => ad.adId === adToPreview?.adId) === undefined && showPreview;

  return (
    <>
      <SavedAdsView
        loading={loading}
        saving={saving}
        onTileClick={onTileClick}
        onFetchNext={onFetchNext}
        onToggleSavedAd={onSaveAdButtonClick}
        tilesContainerRef={tilesContainerRef}
        ads={ads}
        onSortByChange={setSortBy}
        sortBy={sortBy}
        brand={brand}
        trialEndDate={trialEndDate}
        onOpenManageSubscriptionModal={onOpenManageSubscriptionModal}
      />
      <AdCreativePreviewModal
        ad={wasRemovedOnPreview && adToPreview ? { ...adToPreview, isSaved: false } : adToPreview}
        open={showPreview}
        onClose={onCloseModal}
        onToggleSaveAd={onSaveAdButtonClick}
      />
      <BuildPlanModal
        brandId={brand.brandId}
        onClose={onCloseManageSubscriptionModal}
        open={openManageSubscriptionModal}
      />
    </>
  );
};

export default SavedAds;
