import styled from '@emotion/styled';
import { colors, Help, MagicStars, Text } from '@innovationdepartment/proxima-ui';
import TrialEndedPillView from 'ui/TrialEnded/TrialEnded.Pill.View';
import { getDaysRemaining } from 'utils/getDaysRemaining';

const GradientTag = styled.div`
  border-radius: 100px;
  background: linear-gradient(90deg, ${colors.proximaGreen40} 0%, #89d0e9 100%);
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 4px 8px;
  width: 67px;
`;

const HeaderContainer = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.neutral20};
  display: flex;
  height: 69px;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const HelpIconContainer = styled.a`
  border-radius: 18px;
  border: 1px solid ${colors.neutral20};
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const IconLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;

  > svg {
    height: 16px;
    width: 16px;
  }
`;

type InsightsHeaderProps = {
  brandName: string;
  helpLink: string;
  title: string;
  trialEndDate?: string;
  onOpenManageSubscriptionModal?: () => void;
};

const InsightsHeader = (props: InsightsHeaderProps) => {
  const { brandName, helpLink, trialEndDate, onOpenManageSubscriptionModal, title } = props;

  let daysRemaining = 0;

  if (trialEndDate) {
    // Convert unix timestamp into a date
    const trialEndDateConverted = new Date(trialEndDate);

    daysRemaining = getDaysRemaining(trialEndDateConverted);
  }

  const showTrialEndingPill =
    daysRemaining <= 30 && daysRemaining > 0 && onOpenManageSubscriptionModal;

  return (
    <HeaderContainer>
      <HeaderSection>
        <Text color="neutral90" variant="h4" isTermina>
          {title}
        </Text>
        <GradientTag>
          <IconLabelContainer>
            <MagicStars />
            <Text variant="tag2Semibold" color="neutral80">
              Beta
            </Text>
          </IconLabelContainer>
        </GradientTag>
      </HeaderSection>
      <HeaderSection>
        {showTrialEndingPill && (
          <TrialEndedPillView
            respectPosition
            daysRemaining={daysRemaining}
            onOpenManageSubscriptionModal={onOpenManageSubscriptionModal}
          />
        )}
        <Text variant="body2Semibold" color="neutral70">
          {brandName}
        </Text>
        {helpLink && (
          <HelpIconContainer href={helpLink} target="_blank">
            <Help />
          </HelpIconContainer>
        )}
      </HeaderSection>
    </HeaderContainer>
  );
};

export default InsightsHeader;
